import request from '@/server/request'

export default {
  async loadPermissions ({ commit, rootGetters }) {
    return request({ entity: 'permissions', action: 'list' }, rootGetters['admin/getAccessToken'], 'GET')
    .then(async result => {
      if (result.data) {
        commit('setPermissions', result.data)
      }
    })
  },
}
