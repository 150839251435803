import request from '@/server/request'

export default {
  async list ({ rootGetters }) {
    return request({ entity: 'auction.buy_request', action: 'list' }, rootGetters['admin/getAccessToken'], 'GET')
  },

  async approve ({ rootGetters }, payload) {
    return request({ entity: 'auction.buy_request', action: 'approve' }, rootGetters['admin/getAccessToken'], 'POST', payload)
  },

  async decline ({ rootGetters }, payload) {
    return request({ entity: 'auction.buy_request', action: 'decline' }, rootGetters['admin/getAccessToken'], 'POST', payload)
  },
}
