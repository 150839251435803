<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12" sm="9" md="9">
        <h1 class="display-2 font-weight-bold mb-3">
          Lots on Auction
        </h1>
      </v-col>
    </v-row>
    <v-row class="text-center">
      <v-col>
        <v-card>
          <v-list three-line v-if="lots.length > 0">
            <template v-for="(lot, index) in lots">
              <lot-item :key="lot.title" :item="lot" />
              <v-divider :key="index" :inset="lot.inset" />
            </template>
          </v-list>
          <template v-else>
            <p class="subheading font-weight-regular">
              Nobody is selling right now.
              <br /><i>But you always can check our auction for </i> <router-link :to="'/auction/grower'">Grower Lots!</router-link>
            </p>
          </template>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import LotItem from '@/components/Auction/LotItem'

export default {
  name: 'AuctionLots',
  components: {
    LotItem
  },
  async mounted () {
    await this.loadLots(this.token)
  },
  computed: {
    ...mapGetters({
      token: 'user/getAccessToken',
      lots: 'auction/getLots'
    })
  },
  methods: {
    ...mapActions({
      loadLots: 'auction/loadLots'
    })
  }
}
</script>
