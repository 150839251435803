import state from './state'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'

import greenhouse from './greenhouse'
import documents from './documents'

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
  modules: {
    greenhouse,
    documents,
  },
}
