import request from '@/server/request'

export default {
  async login ({ dispatch }, credentials) {
    return request({ entity: 'admin', action: 'login' }, null, 'POST', credentials)
      .then(async credentials => {
        if (credentials.token_type === 'Bearer' && credentials.access_token) {
          await dispatch('loadUserData', credentials.access_token)
            .then(user => {
              if (user.id) {
                dispatch('setAccessToken', credentials.access_token)
              }
            })
        }
      })
  },

  async logout ({ dispatch, rootGetters }) {
    return request({ entity: 'admin', action: 'logout' }, rootGetters['admin/getAccessToken'], 'POST')
      .then(result => {
        dispatch('setAccessToken', null)
        dispatch('setUser', null)
        return result
      })
  },

  async socialLogin ({ dispatch }, payload) {
    return request({ entity: 'admin', action: 'social_login' }, null, 'POST', payload)
      .then(async credentials => {
        if (credentials.token_type === 'Bearer' && credentials.access_token) {
          await dispatch('loadUserData', credentials.access_token)
            .then(user => {
              if (user.id) {
                dispatch('setAccessToken', credentials.access_token)
              }
            })
        }
      })
  },

  // eslint-disable-next-line no-empty-pattern
  async requestPasswordReset ({}, email) {
    return request({ entity: 'admin', action: 'request_password_reset' }, null, 'POST', { email })
  },

  // eslint-disable-next-line no-empty-pattern
  async resetPassword ({}, payload) {
    return request({ entity: 'admin', action: 'reset_password' }, null, 'POST', payload)
  },

  async loadUserData ({ commit }, token) {
    return request({ entity: 'admin', action: 'me' }, token, 'GET')
      .then(user => {
        commit('setUser', user)
        return user
      })
  },

  // eslint-disable-next-line no-empty-pattern
  async verifyEmail ({}, { token, payload }) {
    return request({ entity: 'user.verification', action: 'email' }, token, 'POST', payload)
  },

  async updateProfileInformation ({ commit }, { token, payload }) {
    return request({ entity: 'admin', action: 'update_profile_information' }, token, 'POST', payload)
      .then(user => {
        commit('updateProfileInformation', user)
      })
  },

  setAccessToken ({ commit }, token) {
    commit('setAccessToken', token)
  },
  // eslint-disable-next-line no-empty-pattern
  async changePassword ({}, { token, payload }) {
    return request({ entity: 'admin', action: 'change_password' }, token, 'POST', payload)
  },

  async loadUsers ({ commit, rootGetters }) {
    return request({ entity: 'admin', action: 'list' }, rootGetters['admin/getAccessToken'], 'GET')
      .then(async result => {
        if (result.data) {
          commit('setUsers', result.data)
        }
      })
  },
  loadUser ({ rootGetters }, payload) {
    return request({ entity: 'admin', action: 'get' }, rootGetters['admin/getAccessToken'], 'GET', payload)
  },
  create ({ rootGetters }, payload) {
    return request({ entity: 'admin', action: 'create' }, rootGetters['admin/getAccessToken'], 'POST', payload)
  },
  update ({ rootGetters }, payload) {
    return request({ entity: 'admin', action: 'update' }, rootGetters['admin/getAccessToken'], 'PUT', payload)
  },
}
