<template>
  <v-list-item :key="item.title">
    <v-list-item-avatar size="250" rounded>
      <v-img src="https://st2.depositphotos.com/7210926/10769/v/600/depositphotos_107692594-stock-illustration-vector-illustration-plant-in-pot.jpg"></v-img>
    </v-list-item-avatar>

    <v-list-item-content>
      <v-list-item-title v-html="item.name"></v-list-item-title>
      <v-list-item-subtitle v-html="`В наявності: ${item.available_qty}`"></v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action>
      <template v-if="item.purchaseable">
        <v-list-item-action-text v-text="`$${item.base_price_per_unit}/unit`"></v-list-item-action-text>
        <v-btn @click="buyClick" color="primary" elevation="3">Buy</v-btn>
      </template>
      <template v-else>
        <v-list-item-action-text v-text="`You can\'t purchase this item`"></v-list-item-action-text>
      </template>
    </v-list-item-action>
    <buy-lot :item="item" ref="dialog" :key="item.id" />
  </v-list-item>
</template>
<script>
import BuyLot from '@/components/Auction/Grower/Modals/BuyLot'

export default {
  name: 'ListInventoryItem',
  components: {
    BuyLot
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  methods: {
    buyClick () {
      this.$refs.dialog.openDialog()
    }
  }
}
</script>
