<template>
  <v-app>
    <v-fade-transition mode="out-in">
      <router-view v-if="initialized" />
    </v-fade-transition>

    <v-snackbar
      v-for="message in messages"
      :key="message.id"
      :timeout="message.delay"
      :value="true"
      app
      top
      right
      tile
      :color="message.color"
    >
      {{ message.message }}
    </v-snackbar>
    <v-footer app>
      <!-- -->
    </v-footer>
    <v-overlay :value="overlay || !initialized">
      <v-progress-circular
        v-if="loader || !initialized"
        :size="50"
        color="primary"
        indeterminate
      />
    </v-overlay>
  </v-app>
</template>

<script>
  import '@/styles/overrides.sass'
  import { mapActions, mapGetters } from 'vuex'

  export default {
    name: 'App',
    computed: {
      ...mapGetters({
        overlay: 'ui/getOverlay',
        loader: 'ui/getLoader',
        loaderText: 'ui/getLoaderText',
        messages: 'ui/getMessages',
        user: 'admin/getUser',
      }),
    },
    data: () => ({
      initialized: false,
    }),
    async created () {
      if (this.$cookies.get('XSRF-TOKEN')) {
        await this.setXsrfToken(this.$cookies.get('XSRF-TOKEN'))
      }
      if (localStorage.getItem('access_token')) {
        await this.setAccessToken(localStorage.getItem('access_token'))
        await this.loadUserData(localStorage.getItem('access_token'))
      }
      await this.initializeCsrf()
      this.initialized = true
    },
    methods: {
      ...mapActions({
        setAccessToken: 'admin/setAccessToken',
        loadUserData: 'admin/loadUserData',
        initializeCsrf: 'app/initializeCsrf',
        setXsrfToken: 'app/setXsrfToken',
      }),
    },
    metaInfo: {
      title: 'Grower Invest',
      titleTemplate: '%s | Admin panel',
      htmlAttrs: { lang: 'en' },
      meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      ],
    },
  }
</script>
