<template>
  <v-row>
    <v-col>
      <v-btn
        x-large
        block
        color="red"
        @click="useAuthProvider('google', Google)"
      >
        Google
      </v-btn>
    </v-col>
    <v-col>
      <v-btn
        x-large
        block
        color="blue"
        @click="useAuthProvider('facebook', Facebook)"
      >
        Facebook
      </v-btn>
    </v-col>
    <v-col>
      <v-btn
        x-large
        block
        color="white"
        @click="useAuthProvider('apple', Apple)"
      >
        Apple ID
      </v-btn>
    </v-col>
  </v-row>
</template>
<script>
  import { mapActions } from 'vuex'
  import { Apple, Facebook, Google } from 'universal-social-auth'

  export default {
    name: 'SocialLogin',
    data () {
      return {
        responseData: {},
        Apple,
        Facebook,
        Google,
      }
    },
    methods: {
      ...mapActions({
        login: 'admin/socialLogin',
      }),
      useAuthProvider (provider, proData) {
        this.$Oauth.authenticate(provider, proData)
          .then(response => {
            if (response.code) {
              this.responseData.code = response.code
              this.responseData.provider = provider
              this.useSocialLogin()
            }
          })
          .catch(err => {
            console.error(err)
          })
      },
      useSocialLogin () {
        this.login({
          provider: this.responseData.provider,
          code: this.responseData.code,
        })
          .then(() => {
            this.$router.push('/')
          })
          .catch(err => {
            console.error(err)
          })
      },
    },
  }
</script>
