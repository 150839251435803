<template>
  <section>
    <v-card class="px-4">
      <v-card-text>
        <v-form
          ref="loginForm"
          v-model="valid"
          lazy-validation
        >
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="user.email"
                :rules="rules.email"
                label="E-mail"
                required
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="user.password"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required, rules.min]"
                :type="showPassword ? 'text' : 'password'"
                name="password"
                label="Password"
                hint="At least 8 characters"
                @click:append="showPassword = !showPassword"
              />
            </v-col>
            <v-col
              class="d-flex xsm"
              cols="12"
              sm="6"
            />
            <v-spacer />
            <v-col
              class="d-flex xsm align-end"
              cols="8"
              sm="3"
            >
              <router-link to="reset-password-request">
                Reset Password
              </router-link>
            </v-col>
            <v-col
              class="d-flex xsm align-end"
              cols="4"
              sm="3"
            >
              <v-btn
                x-large
                block
                :disabled="!valid"
                color="success"
                @click="validate"
              >
                Login
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
        <social-login />
      </v-card-text>
    </v-card>
  </section>
</template>

<script>
  import { mapActions } from 'vuex'
  import SocialLogin from '@/components/User/Authentication/SocialLogin'

  export default {
    name: 'Login',
    components: {
      SocialLogin,
    },
    data () {
      return {
        valid: false,
        user: {
          email: '',
          password: '',
        },
        rules: {
          required: value => !!value || 'Required',
          min: v => (v && v.length >= 8) || 'Min 8 characters',
          email: [
            v => !!v || 'Required',
            v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
          ],
        },
        showPassword: false,
      }
    },
    methods: {
      ...mapActions({
        login: 'admin/login',
      }),
      validate () {
        this.login(this.user)
          .then(() => {
            this.$router.push('/')
          })
      },
    },
  }
</script>
