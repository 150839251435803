import state from './state'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'

import grower from './grower'
import buyRequest from './buy_request'

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
  modules: { grower, buyRequest },
}
