import request from '@/server/request'

export default {
  async loadCustomers ({ commit, rootGetters }) {
    return request({ entity: 'customer', action: 'list' }, rootGetters['admin/getAccessToken'], 'GET')
      .then(async result => {
        if (result.data) {
          commit('setCustomers', result.data)
        }
      })
  },
  loadItem ({ rootGetters }, payload) {
    return request({ entity: 'customer', action: 'get' }, rootGetters['admin/getAccessToken'], 'GET', payload)
  },
  update ({ rootGetters }, payload) {
    return request({ entity: 'customer', action: 'update' }, rootGetters['admin/getAccessToken'], 'PUT', payload)
  },
  // eslint-disable-next-line no-empty-pattern
  async changePassword ({}, { token, payload }) {
    return request({ entity: 'customer', action: 'change_password' }, token, 'POST', payload)
  },
}
