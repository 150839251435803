import Vue from 'vue'
import Vuex from 'vuex'
import admin from '@/store/admin'
import app from '@/store/app'
import auction from '@/store/auction'
import dashboard from '@/store/dashboard'
import permissions from '@/store/permissions'
import roles from '@/store/roles'
import customer from '@/store/customer'
import ui from '@/store/ui'
import nomenclature from '@/store/nomenclature'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    admin,
    app,
    auction,
    customer,
    dashboard,
    permissions,
    roles,
    ui,
    nomenclature,
  },
})
