import request from '@/server/request'

export default {
  loadStages ({ commit, rootGetters }, payload) {
    return request({ entity: 'nomenclature.stages', action: 'list' }, rootGetters['admin/getAccessToken'], 'GET', payload)
      .then(async result => {
        if (result.data) {
          commit('setStages', result.data)
        }
      })
  },
  loadStage ({ rootGetters }, payload) {
    return request({ entity: 'nomenclature.stages', action: 'get' }, rootGetters['admin/getAccessToken'], 'GET', payload)
  },
  create ({ rootGetters }, payload) {
    return request({ entity: 'nomenclature.stages', action: 'create' }, rootGetters['admin/getAccessToken'], 'POST', payload)
  },
  update ({ rootGetters }, payload) {
    return request({ entity: 'nomenclature.stages', action: 'update' }, rootGetters['admin/getAccessToken'], 'PUT', payload)
  },
  uploadImage ({ rootGetters }, payload) {
    return request({ entity: 'nomenclature.stages.image', action: 'upload' }, rootGetters['admin/getAccessToken'], 'POST', payload, false, true)
  },
  updateStage ({ commit }, payload) {
    return commit('updateStage', payload)
  },
  addLocalStage ({ commit }, payload) {
    return commit('addLocalStage', payload)
  },
  deleteLocalStage ({ commit }, itemId) {
    return commit('deleteLocalStage', itemId)
  },
}
