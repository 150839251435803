<template>
  <v-container>
    <v-tabs
      v-model="tab"
      show-arrows
      icons-and-text
      dark
      grow
    >
      <v-tabs-slider color="purple darken-4" />
      <v-tab
        v-for="(tab, i) in tabs"
        :key="i"
      >
        <v-icon large>
          {{ tab.icon }}
        </v-icon>
        <div class="text-caption py-1">
          {{ tab.name }}
        </div>
      </v-tab>
      <v-tab-item>
        <login />
      </v-tab-item>
    </v-tabs>
  </v-container>
</template>
<script>
  import Login from '@/components/User/Authentication/Login'

  export default {
    name: 'Authentication',
    components: {
      Login,
    },
    data () {
      return {
        tab: 0,
        tabs: [
          { name: 'Login', icon: 'mdi-account' },
        ],
      }
    },
  }
</script>
