import { make } from 'vuex-pathify'

export default {
  ...make.mutations(state => state),
  showLoader (state, loaderText) {
    state.overlay = true
    state.loader = true
    state.loaderText = loaderText
  },
  hideLoader (state) {
    state.overlay = false
    state.loader = false
    state.loaderText = ''
  },
  pushMessage (state, message) {
    message.id = Math.floor(Math.random() * 9999)
    switch (message.status) {
      case 'error':
        message.color = 'red lighten-1'
        break
      case 'warning':
        message.color = '#ff9800'
        break
      case 'success':
        message.color = 'teal lighten-2'
        break
      case 'notice':
        message.color = 'primary'
        break
      default:
        message.color = 'primary'
        break
    }
    state.messages.push(message)
    const t = setTimeout(() => {
      state.messages.splice(state.messages.indexOf(message), 1)
      clearTimeout(t)
    }, message.delay)
  },
  setDrawer (state, value) {
    state.drawer = value
  },
  setMini (state, value) {
    state.mini = value
  },
}
