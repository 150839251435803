import Vue from 'vue'
import Router from 'vue-router'

import auth from './middleware/auth'
import guest from './middleware/guest'

import Authentication from '@/pages/Authentication'

import Dashboard from '@/pages/Dashboard'

import AuctionGrower from '@/pages/Auction/GrowerLots'
import AuctionLots from '@/pages/Auction/Lots'

import { layout } from '@/util/routes'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    layout('Default', [
      {
        name: 'home',
        path: '/',
        component: Dashboard,
        meta: {
          middleware: auth,
        },
      },
      {
        name: 'user-change-password',
        path: '/me/change-password',
        component: () => import(/* webpackChunkName: "user-change-password" */ './pages/Me/ChangePassword.vue'),
        meta: {
          middleware: auth,
        },
      },
      {
        name: 'user-settings',
        path: '/me/profile-settings',
        component: () => import(/* webpackChunkName: "user-settings" */ './pages/Me/ProfileSettings.vue'),
        meta: {
          middleware: auth,
        },
      },
      {
        name: 'buy-requests',
        path: '/buy-requests',
        component: () => import(/* webpackChunkName: "buy-requests" */ './pages/BuyRequests.vue'),
        meta: {
          middleware: auth,
        },
      },
      {
        name: 'nomenclature-list',
        path: '/nomenclature/list',
        component: () => import(/* webpackChunkName: "nomenclature-list" */ './pages/Nomenclature/Index.vue'),
        meta: {
          middleware: auth,
        },
      },
      {
        name: 'nomenclature-new',
        path: '/nomenclature/new',
        component: () => import(/* webpackChunkName: "nomenclature-new" */ './pages/Nomenclature/Edit.vue'),
        meta: {
          middleware: auth,
        },
      },
      {
        name: 'nomenclature-edit',
        path: '/nomenclature/edit/:id',
        component: () => import(/* webpackChunkName: "nomenclature-edit" */ './pages/Nomenclature/Edit.vue'),
        meta: {
          middleware: auth,
        },
      },
      {
        name: 'auction-grower',
        path: '/auction/grower',
        component: AuctionGrower,
        meta: {
          middleware: auth,
        },
      },
      {
        name: 'customers-list',
        path: '/customers/list',
        component: () => import(/* webpackChunkName: "customers-list" */ './pages/Customers/Index.vue'),
        meta: {
          middleware: auth,
        },
      },
      {
        name: 'customers-edit',
        path: '/customers/edit/:id',
        component: () => import(/* webpackChunkName: "customers-edit" */ './pages/Customers/Edit.vue'),
        meta: {
          middleware: auth,
        },
      },
      {
        name: 'users-list',
        path: '/users/list',
        component: () => import(/* webpackChunkName: "users-list" */ './pages/Users/Index.vue'),
        meta: {
          middleware: auth,
        },
      },
      {
        name: 'users-new',
        path: '/users/new',
        component: () => import(/* webpackChunkName: "users-new" */ './pages/Users/Edit.vue'),
        meta: {
          middleware: auth,
        },
      },
      {
        name: 'users-edit',
        path: '/users/edit/:id',
        component: () => import(/* webpackChunkName: "users-edit" */ './pages/Users/Edit.vue'),
        meta: {
          middleware: auth,
        },
      },
      {
        name: 'roles-list',
        path: '/roles/list',
        component: () => import(/* webpackChunkName: "roles-list" */ './pages/Roles/Index.vue'),
        meta: {
          middleware: auth,
        },
      },
      {
        name: 'roles-new',
        path: '/roles/new',
        component: () => import(/* webpackChunkName: "roles-new" */ './pages/Roles/Edit.vue'),
        meta: {
          middleware: auth,
        },
      },
      {
        name: 'roles-edit',
        path: '/roles/edit/:id',
        component: () => import(/* webpackChunkName: "roles-edit" */ './pages/Roles/Edit.vue'),
        meta: {
          middleware: auth,
        },
      },
    ]),
    {
      name: 'authentication',
      path: '/user/authentication',
      component: Authentication,
      meta: {
        middleware: guest,
      },
    },
    {
      path: '/user/reset-password-request',
      name: 'reset-password-request',
      component: () => import(/* webpackChunkName: "auth" */ './pages/ResetPasswordRequest.vue'),
      meta: {
        middleware: guest,
      },
    },
    {
      path: '/user/reset-password/:email/:token',
      name: 'reset-password',
      component: () => import(/* webpackChunkName: "auth" */ './pages/ResetPassword.vue'),
      meta: {
        middleware: guest,
      },
    },
    {
      name: 'auction-lots',
      path: '/auction/lots',
      component: AuctionLots,
    },
  ],
})

function middlewarePipeline (context, middleware, index) {
  const nextMiddleware = middleware[index]
  if (!nextMiddleware) {
    return context.next
  }
  return () => {
    const nextPipeline = middlewarePipeline(
      context, middleware, index + 1,
    )
    nextMiddleware({ ...context, next: nextPipeline })
  }
}

router.beforeEach((to, from, next) => {
  if (!to.meta.middleware) {
    return next()
  }
  const middleware = Array.isArray(to.meta.middleware) ? to.meta.middleware : [to.meta.middleware]

  const context = {
    from,
    next,
    router,
    to,
  }

  return middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1),
  })
})

export default router
