import request from '@/server/request'

export default {
  async loadRoles ({ commit, rootGetters }) {
    return request({ entity: 'roles', action: 'list' }, rootGetters['admin/getAccessToken'], 'GET')
    .then(async result => {
      if (result.data) {
        commit('setRoles', result.data)
      }
    })
  },
  loadRole ({ rootGetters }, payload) {
    return request({ entity: 'roles', action: 'get' }, rootGetters['admin/getAccessToken'], 'GET', payload)
  },
  create ({ rootGetters }, payload) {
    return request({ entity: 'roles', action: 'create' }, rootGetters['admin/getAccessToken'], 'POST', payload)
  },
  update ({ rootGetters }, payload) {
    return request({ entity: 'roles', action: 'update' }, rootGetters['admin/getAccessToken'], 'PUT', payload)
  },
}
