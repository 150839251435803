import request from '@/server/request'

export default {
  async loadLots({ commit }, token) {
    return request({ entity: 'auction.grower', action: 'load_lots' }, token, 'GET')
      .then(result => {
        commit('setLots', result.data)
        return result
      })
  },
  async buyLot({ commit }, { token, payload}) {
    return request({ entity: 'auction.grower', action: 'buy' }, token, 'POST', payload)
      .then(result => {
        commit('setLots', result.data)
        return result
      })
  }
}
