<template>
  <v-list-item :key="item.title">
    <v-list-item-avatar>
      <v-img :src="item.image"></v-img>
    </v-list-item-avatar>

    <v-list-item-content>
      <v-list-item-title v-html="item.name"></v-list-item-title>
      <v-list-item-subtitle v-html="item.subtitle"></v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action>
      <v-list-item-action-text v-text="`${item.price} грн/кг`"></v-list-item-action-text>
      <v-btn color="primary" elevation="3">Buy</v-btn>
    </v-list-item-action>
  </v-list-item>
</template>
<script>
export default {
  name: 'ListTradeItem',
  props: {
    item: {
      type: Object,
      required: true
    }
  }
}
</script>
