import request from '@/server/request'
import Vue from 'vue'

export default {
  async loadDashboard ({ rootGetters, commit }) {
    return request({ entity: 'dashboard', action: 'load_info' }, rootGetters['admin/getAccessToken'], 'GET')
      .then(result => {
        commit('setDashboardInfo', result.data)
      })
  },
}
