import request from '@/server/request'

export default {
  async loadProducts ({ commit, rootGetters }) {
    return request({ entity: 'nomenclature', action: 'list' }, rootGetters['admin/getAccessToken'], 'GET')
    .then(async result => {
      if (result.data) {
        commit('setNomenclature', result.data)
      }
    })
  },
  loadItem ({ rootGetters }, payload) {
    return request({ entity: 'nomenclature', action: 'get' }, rootGetters['admin/getAccessToken'], 'GET', payload)
  },
  create ({ rootGetters }, payload) {
    payload.stages = rootGetters['nomenclature/stages/getStages']
    return request({ entity: 'nomenclature', action: 'create' }, rootGetters['admin/getAccessToken'], 'POST', payload)
  },
  update ({ rootGetters }, payload) {
    payload.stages = rootGetters['nomenclature/stages/getStages']
    return request({ entity: 'nomenclature', action: 'update' }, rootGetters['admin/getAccessToken'], 'PUT', payload)
  },
  loadProductInitialSales ({ rootGetters }, payload) {
    return request({ entity: 'nomenclature', action: 'get_initial_sales' }, rootGetters['admin/getAccessToken'], 'GET', payload)
  },
}
