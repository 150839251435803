export default {
  overlay: false,
  loader: false,
  loaderText: '',
  messages: [],
  drawer: null,
  drawerImage: true,
  mini: false,
  dark: true,
}
