import { generateString } from '@/util/helpers'

export default {
  setStages (state, items) {
    state.items = items
  },
  updateStage (state, item) {
    state.items.splice(state.items.findIndex(i => i.id === item.id), 1, item)
  },
  addLocalStage (state, payload) {
    state.items.push({
      id: generateString(32),
      product_id: payload.product_id,
      image_path: '',
      stage_age_days: 0,
    })
  },
  deleteLocalStage (state, stageId) {
    state.items.splice(state.items.findIndex(i => i.id === stageId), 1)
  },
}
