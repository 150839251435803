<template>
  <v-dialog v-model="opened" persistent max-width="600px">
    <v-card>
      <v-card-title>
        <span class="text-h5">Auction Item Purchasing</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-slider v-model="qty_for_purchase" color="orange" label="Qty to purchase" thumb-label="always" thumb-color="red"
                  hint="You will not be able to change this value" :min="item.minimum_saleable" :max="item.purchaseable"
              />
            </v-col>
          </v-row>
        </v-container>
        <small>*indicates required field</small>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="closeDialog">
          Close
        </v-btn>
        <v-btn color="blue darken-1" text @click="submit">
          Buy
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'BuyGrowerLotModal',
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      opened: false,
      qty_for_purchase: this.item.purchaseable
    }
  },
  computed: {
    ...mapGetters({
      token: 'user/getAccessToken'
    })
  },
  methods: {
    ...mapActions({
      buyLot: 'auction/grower/buyLot'
    }),
    submit () {
      this.buyLot({
        token: this.token,
        payload: {
          qty: this.qty_for_purchase,
          sale_id: this.item.id
        }
      })
      this.closeDialog()
    },
    openDialog () {
      this.opened = true
    },
    closeDialog () {
      this.opened = false
      this.qty_for_purchase = 0
    }
  }
}
</script>
