import request from '@/server/request'

export default {
  async loadDocuments ({ rootGetters }, payload) {
    return request({ entity: 'customer.documents', action: 'list' }, rootGetters['admin/getAccessToken'], 'GET', payload)
  },
  async approve ({ rootGetters }, payload) {
    return request({ entity: 'customer.documents', action: 'approve' }, rootGetters['admin/getAccessToken'], 'POST', payload)
  },
  async decline ({ rootGetters }, payload) {
    return request({ entity: 'customer.documents', action: 'decline' }, rootGetters['admin/getAccessToken'], 'POST', payload)
  },
}
