import state from './state'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'

import stages from '@/store/nomenclature/stages'

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
  modules: { stages },

}
