import request from '@/server/request'
import Vue from 'vue'

export default {
  async initializeCsrf ({ commit }) {
    return request({ entity: 'app.protector', action: 'csrf' }, null, 'GET', null, false)
      .then(() => {
        commit('setXsrfToken', Vue.$cookies.get('XSRF-TOKEN'))
      })
  },

  setXsrfToken ({ commit }, token) {
    commit('setXsrfToken', token)
  },
}
