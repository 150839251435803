export default {
  setUser (state, user) {
    if (!user) {
      user = {
        id: null,
        name: null,
        email: null,
      }
    }
    state.user = user
  },
  setAccessToken (state, token) {
    if (token) {
      localStorage.setItem('access_token', token)
    } else {
      localStorage.removeItem('access_token')
    }
    state.access_token = token
  },
  updateProfileInformation (state, user) {
    state.user = user
  },
  verifyPhoneNumber (state) {
    state.user.phone_verified = 1
  },
  setUsers (state, users) {
    state.users = users
  },
}
