import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import './plugins'
import router from './router'
import VueRouter from 'vue-router'
import VueCookies from 'vue-cookies'
import Vuex from 'vuex'
import store from './store'
import axios from 'axios'
import UniversalSocialAuth from 'universal-social-auth'
import i18n from './plugins/i18n'

import * as configLocal from '@/config/local.json'

Vue.config.productionTip = false

Vue.use(VueRouter)
Vue.use(Vuex)
Vue.use(VueCookies)

const options = {
  providers: {
    // apple: {
    //   nonce: '**************',
    //   state: '**************',
    //   clientId: '**************',
    //   redirectUri: 'https://myapp.com/auth/github/callback'
    // },
    google: {
      clientId: configLocal.social_auth.google.client_id,
      redirectUri: configLocal.social_auth.google.redirect_uri,
    },
    facebook: {
      clientId: configLocal.social_auth.facebook.client_id,
      redirectUri: configLocal.social_auth.facebook.redirect_uri,
    },
  },
}

const Oauth = new UniversalSocialAuth(axios, options)

Vue.prototype.$axios = axios
Vue.prototype.$Oauth = Oauth

/**
 * We will use another Vue instance as a global
 * event handler (subscribe / emit events)
 */
Vue.prototype.$bus = new Vue()

// Initialize Vue.js application
// eslint-disable-next-line
export default new Vue({
  el: '#app',
  vuetify,
  router,
  store,
  i18n,
  render: h => h(App),
})
